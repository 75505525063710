@import 'fonts';

/* COLORS */
$color__black--text: #212529;
$color__gray--bg: #f5f5f5;
$color__gray--border: #b3b3b3;
$color__gray--text: #9e9e9e;
$color__orange: #f39100;
$color__orange--hover: #f8a01b;
$color__white: #ffffff;

/* FONT STYLES */
@mixin style__headline() {
  @include font__bold;
  font-size: 36px;
  line-height: 36px;
  padding: 0;
  margin: 0 0 50px;
}

@mixin style__text() {
  @include font__normal;
  font-size: 16px;
  line-height: 24px;
  padding: 0;
  margin: 0 0 15px;
}
