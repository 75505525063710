.FrameEmbed {
  font-size: 0;

  &__Frame {
    width: 100%;
    height: 400px;
    border: none;
    overflow: hidden;
  }
}
