@import '../styles/_shared';

.Login {
  padding: 100px 0;
  margin: 0 auto;
  max-width: 350px;

  @include xs {
    padding: 40px 15px;
  }

  &__Headline {
    @include style__headline;
  }

  &__Intro {
    @include style__text;
  }

  &__Form {
    display: flex;
    margin: 20px 0;
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
  }

  &__Input {
    @include font__normal;
    font-size: 16px;
    line-height: 16px;
    padding: 10px 15px;
    margin: 10px 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px $color__gray--border solid;
    border-radius: 10px;

    &:focus {
      outline: none;
      border-color: $color__orange;
    }

    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__EditDeeplink {
    @include font__normal;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: $color__orange;
    background: none;
    border: none;
    font-size: 16px;
    padding: 0;
    display: block;
    margin: 5px 0 0;
    text-align: right;
  }

  &__Submit {
    @include font__normal;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: $color__white;
    background: $color__orange;
    border: none;
    font-size: 16px;
    line-height: 16px;
    display: block;
    text-align: center;
    padding: 12px 15px;
    margin: 10px 0;
    border-radius: 10px;
    cursor: pointer;
    transition: background 0.1s ease-in-out;

    &:hover {
      background: $color__orange--hover;
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
