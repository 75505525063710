$xs: 575px;
$sm: 767px;
$md: 1023px;

/* Large */
@mixin lg() {
  @media (min-width: #{$md + 1px}) {
    @content;
  }
}

/* Medium */
@mixin md() {
  @media (max-width: $md) and (min-width: #{$sm + 1px}) {
    @content;
  }
}

@mixin md-up() {
  @media (min-width: #{$sm + 1px}) {
    @content;
  }
}

@mixin md-down() {
  @media (max-width: $md) {
    @content;
  }
}

/* Small */
@mixin sm() {
  @media (max-width: $sm) and (min-width: #{$xs + 1px}) {
    @content;
  }
}

@mixin sm-up() {
  @media (min-width: #{$xs + 1px}) {
    @content;
  }
}

@mixin sm-down() {
  @media (max-width: $sm) {
    @content;
  }
}

/* Extra small */
@mixin xs() {
  @media (max-width: $xs) {
    @content;
  }
}
