@import '../styles/_shared';

.MenuItem {
  display: inline-block;

  &:not(:last-child) {
    margin-right: 40px;

    @include xs {
      margin-right: 25px;
    }
  }

  &__Link {
    text-decoration: none;
    display: inline-block;
    color: $color__gray--text;
    transition: color 0.1s ease-in-out;
    position: relative;
    padding: 30px 0;

    @include xs {
      padding: 15px 0;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 0;
      background: $color__orange;
      transition: height 0.1s ease-in-out;
    }
  }

  &__Link:hover,
  &--Current &__Link {
    color: $color__black--text;
  }

  &--Current &__Link::after,
  &__Link:hover::after {
    height: 5px;
  }
}
