@import '../styles/_shared';

.App {
  &__MetaBar {
    padding: 10px 0;
    font-size: 14px;
    background: $color__gray--bg;
    color: $color__black--text;
  }

  &__MetaBarContent {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include xs {
      display: block;
    }
  }

  &__MetaBarTitle {
    @include xs {
      margin-bottom: 5px;
    }
  }

  &__Logout {
    color: $color__orange;
    margin-left: 15px;
  }

  &__Header {
    background: $color__white;
    box-shadow: 0 0 4px rgba($color__black--text, 0.2);
  }

  &__HeaderContent {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @include xs {
      display: block;
      padding-top: 20px;
    }
  }

  &__Logo {
    width: 105px;
    height: auto;
    margin-right: 50px;

    @include xs {
      width: 60px;
      margin-right: 0;
    }
  }

  &__Menu {
    list-style: none;
    padding: 0;
    margin: 0;

    @include xs {
      margin-top: 10px;
    }
  }

  &__Footer {
    height: 300px;
    background: #616161;
  }
}
