@import '../styles/_shared';

.Debug {
  padding: 100px 0;

  @include xs {
    padding: 40px 0;
  }

  &__Headline {
    @include style__headline;
  }

  &__Content {
    font-size: 16px;
    padding: 15px;
    display: block;
    background: $color__gray--bg;
    border-radius: 10px;
    color: $color__gray--text;
    overflow: scroll;
    white-space: pre-wrap;
  }
}
